























































































































































































































































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import AnimatedInput from "./AnimatedInput.vue";
import LoadingButton from "./LoadingButton.vue";
import LoadingImage from "./LoadingImage.vue";
import { Action, Getter } from "vuex-class";
import User from "../model/User";
import Group from "@/model/Group";
import { BModal, BTable } from "bootstrap-vue";
import SelectableList from "@/components/SelectableList.vue";
import StoreAppUtils from "@/util/StoreAppUtils";
import StoreApp from "@/model/StoreApp";
import Subscription from "@/model/invoice/Subscription";
import InvoiceContact from "@/model/InvoiceContact";
import SubscriptionUtils from "@/util/SubscriptionUtils";
import { FormWizard } from "vue-form-wizard";
import ProductMapping from "@/model/invoice/ProductMapping";
import SubscriptionUpgrade from "@/model/SubscriptionUpgrade";
import Product from "@/model/invoice/Product";
import App from "@/model/App";
import SelectableGroup from "@/util/SelectableGroup";

@Component({
  components: {
    AnimatedInput, LoadingButton, LoadingImage, SelectableList
  }
})
export default class UserNew extends Vue {
  @Getter getMailDomains!: string[];
  @Getter getUsers!: User[];
  @Getter getGroups!: Group[];
  @Getter getApps!: App[];
  @Getter getStoreApps!: StoreApp[];
  @Getter loggedInIsSigning!: boolean;
  @Getter getInvoiceContact!: InvoiceContact | null;
  @Getter getPaymentInterval!: number | null;
  @Getter getSubscriptions!: Subscription[];

  @Action GET_PAYMENT_OPTIONS!: any;
  @Action PURCHASE_SERVICE!:({ planId, userCount }: { planId: string, userCount: number }) => Promise<any>;
  @Action UPGRADE_SERVICE!:({ subscriptionId, planId, userCount }: { subscriptionId: number, planId: string, userCount: number }) => Promise<any>;
  @Action GET_USERS!: () => Promise<any>;
  @Action CREATE_USER!: (user: User) => Promise<any>;
  @Action SET_PROFILE_PICTURE!: ({ userId, file }: { userId: string, file: File }) => Promise<any>;
  @Action GET_GROUPS!: () => Promise<Group[]>;
  @Action GET_STORE_APPS!: any;

  @Prop(BModal) modal!: BModal;

  @Ref('appsForUserSelector') appsForUserSelector!: BTable;
  @Ref('imagedisplay') imagedisplay!: HTMLImageElement;
  @Ref('newUserWizard') newUserWizard!: FormWizard;

  subsUtil: SubscriptionUtils = new SubscriptionUtils();

  step: number = 0;
  setStep(current: number, next: number) {
    this.step = next;
  }

  labelUid: string = this.$pgettext("label", "Username");
  labelFirstName: string = this.$pgettext("label", "First name");
  labelLastName: string = this.$pgettext("label", "Last name");
  labelMail: string = this.$pgettext("label", "Email");
  labelExternMail: string = this.$pgettext("label", "Extern email");
  labelInternMail: string = this.$pgettext("label", "Intern email");
  labelPw: string = this.$pgettext("label", "Password");
  labelPwRepeat: string = this.$pgettext("label", "Repeat password");

  acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  systemGroups = [
    {
      dn: 'cn=admin,ou=Groups,dc=uniki,dc=de',
      cn: "admin",
      name: this.$gettext("Administrator"),
      description: this.$gettext("May configure the system, install and uninstall apps.")
    },
    {
      dn: 'cn=signing,ou=Groups,dc=uniki,dc=de',
      cn: "signing",
      name: this.$gettext("Manager"),
      description: this.$gettext("May obtain subscriptions for services, install apps and edit app groups."),
      requires: 'cn=admin,ou=Groups,dc=uniki,dc=de'
    }
  ];

  pwStrongText: string = this.$pgettext("password-strength-info", "Password strength: Strong");
  pwOkText: string = this.$pgettext("password-strength-info", "Password strength: OK");
  pwBadText: string = this.$pgettext("password-strength-info", "Password strength: Bad");

  strongRegex: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
  //at least 8 chars, lower/upper case letters und numbers
  mediumRegex: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

  image: File | null = null;
  isCheckedUsername: boolean = false;
  isCheckedPassword: boolean = false;
  isCustomInterEmail: boolean = false;
  givenName: string = "";
  surName: string = "";
  cn: string = "";
  password: string = "";
  passwordRepeat: string = "";
  externalEmail: string = "";
  internalEmail: string = "";
  posixGroupName: string = "user";
  mailDomain: string = '';
  selectedAppGroupsInternal: string[] = [];
  selectedSystemGroups: string[] = [];
  selectedSecondaryGroups: any = {};
  showNoGroupsModal: boolean = false;

  get subscriptionsToUpgrade(): SubscriptionUpgrade[] {
    const combinedByProduct: SubscriptionUpgrade[] = this.subscriptions;
    const toUpgrade: SubscriptionUpgrade[] = [];
    for (let subscription of combinedByProduct) {
      if (subscription.subscription && subscription.subscription.productType === 'VARIABLE_SUPPORT' && subscription.seatsToBuy > 0) {
        toUpgrade.push(subscription);
      } else if (subscription.seatsToBuy > 0 && !toUpgrade.includes(subscription)) {
        let product: Product | null = subscription.product || (subscription.productMapping ? subscription.productMapping.product : null);
        if (product && product.appId) {
          const thisAppIds: string[] = product.appId.split(',');
          let mustAdd: boolean = true;
          for (let other of combinedByProduct) {
            if (other !== subscription) {
              let otherProduct: Product | null = other.product || (other.productMapping ? other.productMapping.product : null);
              if (otherProduct && otherProduct.appId) {
                const otherAppIds: string[] = [];
                for (let appOrProduct of otherProduct.appId.split(',')) {
                  otherAppIds.push(appOrProduct);
                }
                if (!thisAppIds.find((appOrProduct: string) => !otherAppIds.includes(appOrProduct))) {
                  mustAdd = false;
                }
              }
            }
          }
          if (mustAdd) {
            toUpgrade.push(subscription);
          }
        } else {
          toUpgrade.push(subscription);
        }
      }
    }
    return toUpgrade;
  }

  get subscriptions(): SubscriptionUpgrade[] {
    let subscriptions: SubscriptionUpgrade[] = [];

    for (let subscription of this.getSubscriptions) {
      if (subscription.productType === 'VARIABLE_SUPPORT' && [ 'ACTIVE', 'TRIAL' ].indexOf(subscription.state) >= 0) {
        let storeApp: StoreApp | null = this.getStoreApps.find((stApp: StoreApp) => {
          return Boolean(stApp.subscriptions && stApp.subscriptions.find(sub => sub.id === subscription.id));
        }) || null;
        let userCount: number | null = null;
        let price: number | null = null;
        let taxRate: number = 1900;
        let productMapping: ProductMapping | null = null;
        if (storeApp) {
          userCount = this.subsUtil.seatsActive(storeApp);
          price = this.subsUtil.price(storeApp, this.getPaymentInterval) || 0;
          taxRate = this.subsUtil.taxRate(storeApp);
          productMapping = this.subsUtil.getProductForSubscriptionAndApp(subscription, storeApp) || null;
        } else if (subscription.products && subscription.products.length > 0) {
          userCount = this.subsUtil.getUserCountForSubscription(subscription);
          productMapping = subscription.products[0];
          price = productMapping.netAmount || 0;
          if (productMapping.taxRate && productMapping.taxRate.taxRate !== null) {
            taxRate = productMapping.taxRate.taxRate;
          } else {
            taxRate = 1900;
          }
        }
        if (price !== null && userCount !== null) {
          subscriptions.push({
            subscription: subscription,
            productMapping: productMapping,
            product: null,
            storeApp: storeApp,
            seatsActive: userCount,
            seatsRequired: this.getUsers.length + 1,
            seatsToBuy: Math.max(this.getUsers.length + 1 - userCount, 0),
            price: price,
            taxRate: taxRate
          });
        }
      }
    }

    for (const groupWithApp of this.groupsWithApps) {
      if (this.subsUtil.isPaidApp(groupWithApp.storeApp, groupWithApp.app)) {
        let seatsToBuy: SubscriptionUpgrade[] = this.seatsToBuy(groupWithApp);
        for (let upgrade of seatsToBuy) {
          if (upgrade.subscription && upgrade.subscription.productType !== 'VARIABLE_SUPPORT') {
            subscriptions.push(upgrade);
          } else if (upgrade.product && upgrade.product.type !== 'VARIABLE_SUPPORT') {
            subscriptions.push(upgrade);
          }
        }
      }
    }

    let combinedByProduct: SubscriptionUpgrade[] = [];
    for (let subscription of subscriptions) {
      if (subscription.productMapping && subscription.productMapping.product) {
        const product: Product = subscription.productMapping.product;
        const alreadyAdded: SubscriptionUpgrade | undefined = combinedByProduct.find(s => Boolean(s.productMapping && s.productMapping.product && s.productMapping.product.id === product.id));
        if (alreadyAdded) {
          alreadyAdded.seatsRequired = Math.max(alreadyAdded.seatsRequired, subscription.seatsRequired);
          alreadyAdded.seatsToBuy = Math.max(alreadyAdded.seatsToBuy - subscription.seatsActive, 0);
        } else {
          combinedByProduct.push(subscription);
        }
      } else if (subscription.product) {
        const product: Product = subscription.product;
        const alreadyAdded: SubscriptionUpgrade | undefined = combinedByProduct.find(s => Boolean(s.product && s.product.id === product.id));
        if (alreadyAdded) {
          alreadyAdded.seatsRequired = Math.max(alreadyAdded.seatsRequired, subscription.seatsRequired);
          alreadyAdded.seatsToBuy = Math.max(alreadyAdded.seatsToBuy - subscription.seatsActive, 0);
        } else {
          combinedByProduct.push(subscription);
        }
      }
    }

    return combinedByProduct;
  }

  get onlyFreeApps(): boolean {
    return this.groupsWithApps.filter((groupWithApp: { dn: string, group: Group, app: App, storeApp: StoreApp }) => {
      return this.subsUtil.isPaidApp(groupWithApp.storeApp, groupWithApp.app); //creating list containing only paid apps
    }).length <= 0; // empty list means no paid apps
  }

  get needsUprade(): boolean {
    return this.subscriptionsToUpgrade.length > 0;
  }

  getStoreAppById(storeAppId: string): StoreApp | null {
    return this.getStoreApps.find((value: StoreApp) => {
      return value.id === storeAppId;
    }) || null;
  }

  seatsToBuy(groupWithApp: { dn: string, group: Group, app: App, storeApp: StoreApp, secondaryGroups: SelectableGroup[] | null }): SubscriptionUpgrade[] {
    const selectedUsers: string[] = [...(groupWithApp.group.uniqueMember || [])] || [];
    const selectedGroups: any = {};
    for (let selectableGroup of (groupWithApp.secondaryGroups || [])) {
      if (selectableGroup.group) {
        const usersInGroup = [...(selectableGroup.group.uniqueMember || [])];
        for (let userDn of usersInGroup) {
          selectedGroups[userDn] = selectedGroups[userDn] || [];
          if (!selectedGroups[userDn].find((s: SelectableGroup) => s.id === selectableGroup.id)) {
            selectedGroups[userDn].push(selectableGroup);
          }
        }
      }
    }
    if (groupWithApp.group.dn && this.selectedAppGroups.includes(groupWithApp.group.dn)) {
      selectedUsers.push('new-user');
      if (groupWithApp.app.instanceId && Array.isArray(this.selectedSecondaryGroups[groupWithApp.app.instanceId])) {
        selectedGroups['new-user'] = [...this.selectedSecondaryGroups[groupWithApp.app.instanceId]];
      }
    }

    return this.subsUtil.seatsRequired(groupWithApp.storeApp, this.getUsers.length, selectedUsers, selectedGroups, this.getPaymentInterval);
  }

  get totalAmount(): number {
    let total: number = 0;
    for (let s of this.subscriptionsToUpgrade) {
      total += s.seatsToBuy * s.price * (1 + s.taxRate / 10000);
    }
    return total;
  }

  buyUpgradeAndCreate(): Promise<any> {
    for (const subscriptionUpgrade of this.subscriptionsToUpgrade) {
      let subscriptionId: number | null = null;
      let productId: string | null = null;
      if (subscriptionUpgrade.subscription) {
        subscriptionId = subscriptionUpgrade.subscription.id;
        if (subscriptionUpgrade.productMapping && subscriptionUpgrade.productMapping.product) {
          productId = subscriptionUpgrade.productMapping.product.id;
        } else if (subscriptionUpgrade.storeApp) {
          let productMapping: ProductMapping | undefined = this.subsUtil.getProductForSubscriptionAndApp(subscriptionUpgrade.subscription, subscriptionUpgrade.storeApp);
          if (productMapping && productMapping.product) {
            productId = productMapping.product.id;
          }
        }
      } else if (subscriptionUpgrade.storeApp) {
        let product: Product | null = this.subsUtil.getProduct(subscriptionUpgrade.storeApp);
        if (product) {
          productId = product.id;
        }
      }

      if (subscriptionId !== null && productId) {
        this.UPGRADE_SERVICE({
          subscriptionId: subscriptionId, planId: productId, userCount: subscriptionUpgrade.seatsRequired
        }).then((subscription: Subscription) => {
          this.$snotify.success(this.$pgettext("notification", "Updated subscription."));
          return subscription;
        }).catch(() => {
          this.$snotify.error(this.$pgettext("notification", "Subscription was not updated."));
        });
      } else if (productId) {
        this.PURCHASE_SERVICE({
          planId: productId, userCount: subscriptionUpgrade.seatsRequired
        }).then((subscription: Subscription) => {
          this.$snotify.success(this.$pgettext("notification", "Successfully subscribed to app"));
          return subscription;
        }).catch((error: { response: { data: { message: string; } } }) => {
          this.$snotify.error(error.response.data.message, this.$pgettext("notification", "Could not subscribe to app"));
        });
      }
    }

    return this.create().finally(() => {
      this.GET_STORE_APPS();
    });
  }

  get hasPaymentMethod(): boolean {
    if (this.getInvoiceContact && this.getInvoiceContact.paymentOptions) {
      return this.getInvoiceContact.paymentOptions.length > 0;
    }
    return false;
  }
  //endregion

  get getPwStrength(): string {
    if (this.strongRegex.test(this.password)) {
      return this.pwStrongText;
    } else if (this.mediumRegex.test(this.password)) {
      return this.pwOkText;
    } else {
      return this.pwBadText;
    }
  }

  get getPwInfoColor(): string {
    let strength: string = this.getPwStrength;
    if (strength === this.pwStrongText) return "success";
    else if (strength === this.pwOkText) return "warning";
    else return "danger";
  }

  get internalEmailAddressLabel(): string {
    if (this.isCheckedUsername) {
      return this.$gettext("Internal email address");
    } else {
      return this.$gettext("Username");
    }
  }

  get groupsWithApps(): { dn: string, group: Group, app: App, storeApp: StoreApp, secondaryGroups: SelectableGroup[] | null, disabled: boolean }[] {
    let storeApps: { dn: string, group: Group, app: App, storeApp: StoreApp, secondaryGroups: SelectableGroup[] | null, disabled: boolean }[] = [];
    for (const group of this.getGroups) {
      const app = this.installedApp(group);
      if (group.dn && app && StoreAppUtils.appSupportsGroups(app) && app.storeApp && app.storeApp.id) {
        let storeApp: StoreApp | null = this.getStoreAppById(app.storeApp.id);
        if (storeApp) {
          const secondaryGroups: SelectableGroup[] | null = StoreAppUtils.getSecondaryAppGroupsOfInstalledApp(app, this.getGroups);
          storeApps.push({ dn: group.dn, group: group, app: app, storeApp: storeApp, secondaryGroups: secondaryGroups, disabled: false });
        }
      }
    }
    for (let groupWithApp of storeApps) {
      if (groupWithApp.app.instanceId && this.selectedSecondaryGroups[groupWithApp.app.instanceId]) {
        for (let secondaryGroup of (groupWithApp.secondaryGroups || [])) {
          if (secondaryGroup.requires && secondaryGroup.requires.apps && this.selectedSecondaryGroups[groupWithApp.app.instanceId].find((s: SelectableGroup) => s.id === secondaryGroup.id)) {
            for (let requiredStoreAppId of secondaryGroup.requires.apps) {
              const groupWithApp = storeApps.find(g => g.storeApp.id === requiredStoreAppId);
              if (groupWithApp) {
                groupWithApp.disabled = true;
              }
            }
          }
        }
      }
    }
    return storeApps;
  }

  get selectedAppGroups(): string[] {
    const selectedAppGroups = [...this.selectedAppGroupsInternal];
    const groupsWithApps: { dn: string, group: Group, app: App, storeApp: StoreApp, secondaryGroups: SelectableGroup[] | null }[] = this.groupsWithApps;
    for (const data of groupsWithApps) {
      if (data.group.dn && data.app.instanceId && selectedAppGroups.indexOf(data.group.dn) < 0 &&
          Array.isArray(this.selectedSecondaryGroups[data.app.instanceId] && this.selectedSecondaryGroups[data.app.instanceId].length > 0)) {
        selectedAppGroups.push(data.group.dn);
      }
    }
    return selectedAppGroups;
  }

  set selectedAppGroups(selectedAppGroups: string[]) {
    this.selectedAppGroupsInternal = [...selectedAppGroups];
    const groupsWithApps: { dn: string, group: Group, app: App, storeApp: StoreApp, secondaryGroups: SelectableGroup[] | null }[] = this.groupsWithApps;
    for (const data of groupsWithApps) {
      if (data.group.dn && data.app.instanceId && selectedAppGroups.indexOf(data.group.dn) < 0) {
        this.$set(this.selectedSecondaryGroups, data.app.instanceId, []);
      } else if (data.group.dn && data.app.instanceId && data.secondaryGroups) {
        if (!this.selectedSecondaryGroups[data.app.instanceId] || !Array.isArray(this.selectedSecondaryGroups[data.app.instanceId])) {
          this.$set(this.selectedSecondaryGroups, data.app.instanceId, []);
        }
        for (let secondaryGroup of data.secondaryGroups) {
          if (secondaryGroup.required) {
            if (this.selectedSecondaryGroups[data.app.instanceId] && !this.selectedSecondaryGroups[data.app.instanceId].find((s: SelectableGroup) => s.id === secondaryGroup.id)) {
              this.selectedSecondaryGroups[data.app.instanceId].push(secondaryGroup);
            }
          }
          if (secondaryGroup.requires && secondaryGroup.requires.groups && this.selectedSecondaryGroups[data.app.instanceId].find((s: SelectableGroup) => s.id === secondaryGroup.id)) {
            for (let requiredSecondaryGroupId of secondaryGroup.requires.groups) {
              const requiredSecondaryGroup = (data.secondaryGroups || []).find(o => o.id === requiredSecondaryGroupId);
              if (requiredSecondaryGroup && !this.selectedSecondaryGroups[data.app.instanceId].includes(requiredSecondaryGroup)) {
                this.selectedSecondaryGroups[data.app.instanceId].push(requiredSecondaryGroup);
              }
            }
          }
        }
        for (let secondaryGroup of data.secondaryGroups) {
          if (secondaryGroup.requires && secondaryGroup.requires.apps && this.selectedSecondaryGroups[data.app.instanceId].find((s: SelectableGroup) => s.id === secondaryGroup.id)) {
            for (let requiredStoreAppId of secondaryGroup.requires.apps) {
              const groupWithApp = groupsWithApps.find(g => g.storeApp.id === requiredStoreAppId);
              if (groupWithApp && !selectedAppGroups.includes(groupWithApp.dn)) {
                selectedAppGroups.push(groupWithApp.dn);
              }
            }
          }
        }
        if (selectedAppGroups.length !== this.selectedAppGroupsInternal.length) {
          this.selectedAppGroups = selectedAppGroups;
        }
      }
    }
  }

  get memberOf(): string[] {
    const memberOf: string[] = [...this.selectedAppGroups];
    for (const groupWithApp of this.groupsWithApps) {
      const secondaryGroups: SelectableGroup[] | null = StoreAppUtils.getSecondaryAppGroupsOfInstalledApp(groupWithApp.app, this.getGroups);
      if (secondaryGroups && groupWithApp.app.instanceId && this.selectedSecondaryGroups[groupWithApp.app.instanceId]) {
        for (const group of this.selectedSecondaryGroups[groupWithApp.app.instanceId]) {
          if (group && group.group && group.group.dn && memberOf.indexOf(group.group.dn) < 0) {
            memberOf.push(group.group.dn);
          }
        }
      }
    }
    memberOf.push(...this.selectedSystemGroups);
    return memberOf;
  }

  installedApp(group: Group): App | undefined {
    return this.getApps.find(app => app.userGroup === group.dn);
  }

  replaceUmlauts(s: string): string {
    s = s.replace(/ä/gi, "ae");
    s = s.replace(/ü/gi, "ue");
    s = s.replace(/ö/gi, "oe");
    s = s.replace(/ß/gi, "ss");
    return s;
  }

  passwordsDontMatch(): boolean {
    return this.password !== this.passwordRepeat;
  }

  setMailDomain(value: string) {
    this.mailDomain = value;
  }

  onInternEmailChanged() {
    if (this.internalEmail) {
      this.internalEmail = this.internalEmail.replace('@', '.');
      this.isCustomInterEmail = true;
      return;
    }
    this.isCustomInterEmail = false;
  }

  onNameChanged() {
    if (this.isCustomInterEmail) return;
    if (this.surName && this.givenName) {
      this.internalEmail = (this.givenName + '.' + this.surName).toLowerCase();
    } else if (this.givenName) {
      this.internalEmail = this.givenName;
    } else {
      this.internalEmail = this.surName;
    }
    this.internalEmail = this.replaceUmlauts(this.internalEmail.replace('@', '').toLowerCase());
  }

  createUserObject(): User {
    let user = new User();
    user.givenname = this.givenName;
    user.surname = this.surName;

    user.userPassword = !this.isCheckedPassword ? this.password : null;
    user.externEmail = this.externalEmail;
    user.internEmail = this.internalEmail + '@' + this.mailDomain;
    if (this.isCheckedUsername) {
      user.cn = this.cn;
    } else {
      user.cn = user.internEmail;
    }

    user.posixGroupName = this.selectedSystemGroups.find(dn => dn.startsWith('cn=admin,')) ? 'admin' : 'user';
    user.memberOf = this.memberOf.filter(dn => !dn.startsWith('cn=admin,'));

    //TODO: What's the purpose of this?
    this.getGroups.filter((group: Group) => {
      const app: App | undefined = this.installedApp(group);
      return app && !StoreAppUtils.appSupportsGroups(app);
    }).map((group: Group) => {
      return group.dn;
    }).forEach((dn: string | null) => {
      if (dn && user.memberOf && user.memberOf.indexOf(dn) < 0) user.memberOf.push(dn);
    });

    return user;
  }

  async create() {
    //No Groups? Show modal. if modal already active? -> Create user
    if (this.selectedAppGroups.length === 0 && !this.showNoGroupsModal) {
      this.$bvModal.show('noGroupsModal');
      return;
    }

    let userToCreate = this.createUserObject();
    return this.CREATE_USER(userToCreate).then(() => {
      this.$snotify.success(this.$pgettext("notification", "User saved successfully."));
      if (this.image !== null && userToCreate.cn !== null) {
        return this.SET_PROFILE_PICTURE({ userId: userToCreate.cn, file: this.image }).then(() => {
          this.$snotify.success(this.$pgettext("notification", "Profile picture uploaded successfully."));
          this.reset();
        }).catch(error => {
          this.$snotify.error(error.response.data.message,
            this.$pgettext("notification", "Could not upload profile picture."));
          this.reset();
        });
      } else {
        this.reset();
      }
    }).catch(error => {
      this.$snotify.error(error.response.data.message, this.$pgettext("notification", "Could not save user."));
    }).finally(() => {
      this.GET_USERS();
    });
  }

  reset(): void {
    this.image = null;
    this.isCheckedUsername = false;
    this.isCheckedPassword = false;
    this.isCustomInterEmail = false;
    this.givenName = "";
    this.surName = "";
    this.cn = "";
    this.password = "";
    this.passwordRepeat = "";
    this.externalEmail = "";
    this.internalEmail = "";
    this.posixGroupName = "user";
    this.selectedAppGroups = [];
    this.selectedSystemGroups = [];
    this.showNoGroupsModal = false;
    this.newUserWizard.reset();
    this.closeModals();
  }

  redirectToCustomerCenter() {
    if (this.getInvoiceContact && this.getInvoiceContact.portalURL) {
      window.open(this.getInvoiceContact.portalURL, '_blank');
    } else {
      this.$snotify.error(this.$pgettext("notification", "Could not get customer center url."));
    }
  }

  closeModals() {
    this.$bvModal.hide('noGroupsModal');
    if (this.modal) this.modal.hide();
  }

  async setPicture(image: File) {
    let reader = new FileReader();
    let output: HTMLImageElement = this.imagedisplay;
    if (image && this.isFileImage(image)) {
      this.image = image;
      reader.onload = function () {
        output.src = reader.result as string;
        output.style.display = "";
      };
      reader.readAsDataURL(image);
    } else {
      this.image = null;
      output.src = "";
      output.style.display = "none";
    }
  }

  isFileImage(file: File) {
      return file && this.acceptedImageTypes.includes(file['type']);
  }

  mounted() {
    if (this.getMailDomains.length > 0) {
      this.mailDomain = this.getMailDomains[0];
    }
  }
};
