

































































































































































import { Vue, Component, Ref } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import SeafileLibrary from "@/model/SeafileLibrary";
import SelectableList from "@/components/SelectableList.vue";
import LoadingImage from "@/components/LoadingImage.vue";
import { FormWizard } from "vue-form-wizard";
import USBStorageDrive from "@/model/backup/USBStorageDrive";
import ImportableData from "@/model/ImportableData";
import USBStoragePartition from "@/model/backup/USBStoragePartition";
import DataImportJob from "@/model/DataImportJob";
import App from "@/model/App";
import StoreApp from "@/model/StoreApp";
import LibraryProgress from "@/model/LibraryProgress";

@Component({
  components: {
    LoadingButton,
    AnimatedInput,
    SelectableList,
    LoadingImage
  }
})
export default class DataImport extends Vue {

  libraryNameLabel: string = this.$pgettext("dataimport", "Name");

  @Action CREATE_IMPORT_JOB!: (job: DataImportJob) => Promise<LibraryProgress[]>;
  @Action GET_SEAFILE_LIBRARIES!: () => Promise<SeafileLibrary[]>;
  @Action GET_USB_DRIVES!: () => Promise<USBStorageDrive[]>;
  @Action GET_IMPORTABLE_DATA!: (deviceId: string) => Promise<ImportableData[]>;
  @Action GET_INSTALLED_APPS!: any;
  @Action GET_STORE_APPS!: any;

  @Getter getApps!: App[];
  @Getter getStoreApps!: StoreApp[];
  @Getter getLibraries!: SeafileLibrary[];
  @Getter getSyncProgress!: LibraryProgress[];

  @Ref('startImportJobWizard') startImportJobWizard!: FormWizard;

  modalTitle: string = '';
  labelUsername: string = this.$gettext('Username');
  labelPassword: string = this.$gettext('Password');
  labelDomain: string = this.$gettext('Domain / Workgroup');
  labelServerAddress: string = this.$gettext('Server Address');
  labelPort: string = this.$gettext('Server Port');
  labelPath: string = this.$gettext('Share Name / Path');

  availableProtocols: { name: string, mask: string, description: string }[] = [
    { name: 'ftp', mask: this.$gettext('user:password@server:port/path'), description: this.$gettext('FTP Server') },
    { name: 'sftp', mask: this.$gettext('user:password@server:port/path'), description: this.$gettext('SSH Server') },
    { name: 'smb', mask: this.$gettext('user:password@dpmain/server/share'), description: this.$gettext('Samba Server / Windows Share') }
  ];

  importOptions: any[] = [
    {
      text: this.$pgettext("dataimport", "Import all files and directories into a new library."),
      id: 'All'
    },
    {
      text: this.$pgettext("dataimport", "Select directories to import into seperate libraries."),
      id: 'Selected'
    }
  ];

  step: number = 0;
  setStep(current: number, next: number) {
    this.step = next;
  }

  fromUSB: boolean = false;
  libraryName: string | null = null;
  importOption: string = 'All';
  networkProtocol: string = "smb";
  networkTargetUser: string | null = null;
  networkTargetPassword: string | null = null;
  networkTargetServerAddress: string | null = null;
  networkTargetPort: string | null = null;
  networkTargetDomain: string | null = null;
  networkTargetPath: string | null = null;
  selectedDiskOrPartition: string | null = null;
  selectedDirectories: string[] = [];
  usbDisks: USBStorageDrive[] = [];
  importableData: ImportableData[] = [];

  get seafileInstalled(): boolean {
    return Boolean(this.getApps && this.getApps.find(app => {
      return Boolean(app.storeId && (app.storeId.startsWith('de.uniki.seafile') || app.storeId.startsWith('de.uniki.files')));
    }));
  }

  goToSeafileInStore(): void {
    if (this.getStoreApps) {
      let storeApp: StoreApp | undefined = this.getStoreApps.find((app: StoreApp) => {
        return Boolean(app.id && app.id === 'de.uniki.seafile');
      });
      if (storeApp) {
        this.$router.push('/store/' + storeApp.id);
      }
    }
  }

  nameIsSelected(name: string): boolean {
    return !!this.selectedDirectories.find(selected => selected === name);
  }

  libraryWithNameExists(name: string): boolean {
    return this.getLibraries && !!this.getLibraries.find(library => library.name === name);
  }

  get importableFilesAndDirectories(): ImportableData[] {
    return this.importableData || [];
  }

  get importableDirectories(): ImportableData[] {
    return this.importableData ? this.importableData.filter(data => data.type === 'DIRECTORY') : [];
  }

  get usablePartitions(): { name: string, id: string }[] {
    let disksAndPartitions: { name: string, id: string }[] = [];
    this.usbDisks.forEach(disk => {
      if (disk.partitions) {
        for (let i = 0; i < disk.partitions.length; i++) {
          let partition: USBStoragePartition = disk.partitions[i];
          if (partition.fsUUID && !disksAndPartitions.find(part => part.id === partition.fsUUID)) {
            let name: string = (disk.model || disk.serial || '') + ' Partition ' + (i + 1);
            disksAndPartitions.push({ name: name, id: partition.fsUUID });
          }
        }
      }
    });
    return disksAndPartitions;
  }

  openStartUSBImportJobModal(): Promise<void> {
    this.fromUSB = true;
    this.modalTitle = this.$gettext('Import Data from USB drive');
    return this.GET_USB_DRIVES().then((response: USBStorageDrive[]) => {
      this.usbDisks = response;
      this.$bvModal.show('startImportJobModal');
      this.$nextTick(() => {
        this.startImportJobWizard.reset();
      });
    }).catch(error => {
      let message: string = error && error.response && error.response.data && error.response.data.message;
      let title: string = this.$pgettext("notification", "Could not get list of USB drives. An error occurred.");
      if (message) {
        this.$snotify.error(message, title);
      } else {
        this.$snotify.error(title);
      }
    });
  }

  openStartNetworkImportJobModal() {
    this.fromUSB = false;
    this.modalTitle = this.$gettext('Import Data from Network share');
    this.$bvModal.show('startImportJobModal');
    this.$nextTick(() => {
      this.startImportJobWizard.reset();
    });
  }

  goToSecondPage(props: any): Promise<any> {
    let deviceId: string;
    if (this.fromUSB) {
      deviceId = this.selectedDiskOrPartition || '';
    } else {
      deviceId = this.networkTarget;
    }

    return this.GET_IMPORTABLE_DATA(deviceId).then((data: ImportableData[]) => {
      this.importableData = data;
      props.nextTab();
    }).catch(error => {
      let message: string = error && error.response && error.response.data && error.response.data.message;
      let title: string;
      if (this.fromUSB) {
        title = this.$pgettext("notification", "Could not read content of USB drive. An error occurred.");
      } else {
        title = this.$pgettext("notification", "Could not read content of network share. An error occurred.");
      }
      if (message) {
        this.$snotify.error(message, title);
      } else {
        this.$snotify.error(title);
      }
    });
  }

  get networkTarget(): string {
    let url = this.networkProtocol + '://' + this.esc(this.networkTargetUser) + ':' + this.esc(this.networkTargetPassword) + '@';
    if (this.networkProtocol === 'smb') {
      url += this.esc(this.networkTargetDomain) + '/' + this.esc(this.networkTargetServerAddress) + '/' + this.esc(this.networkTargetPath);
    } else if (this.networkProtocol === 'sftp' && this.networkTargetPort) {
      url += this.esc(this.networkTargetServerAddress) + ':' + this.esc(this.networkTargetPort) + ':' + this.esc(this.networkTargetPath);
    } else if (this.networkProtocol === 'sftp') {
      url += this.esc(this.networkTargetServerAddress) + ':' + this.esc(this.networkTargetPath);
    } else if (this.networkTargetPort) {
      url += this.esc(this.networkTargetServerAddress) + ':' + this.esc(this.networkTargetPort) + '/' + this.esc(this.networkTargetPath);
    } else {
      url += this.esc(this.networkTargetServerAddress) + '/' + this.esc(this.networkTargetPath);
    }
    return url;
  }

  esc(val: string | null): string {
    return (val || '').replace(/:/g, '\\:').replace(/\//g, '\\/').replace(/@/g, '\\@');
  }

  startImportJob(): Promise<any> {
    let job: DataImportJob = new DataImportJob();
    if (this.fromUSB) {
      job.device = this.selectedDiskOrPartition;
    } else {
      job.device = this.networkTarget;
    }

    let importableData: ImportableData[] = this.importableData || [];
    if (this.importOption === 'All') {
      job.library = this.libraryName;
      job.files = (this.importableData || []).map(data => data.name || '').filter(name => name !== '');
    } else {
      job.files = this.selectedDirectories.map(name => importableData.find(data => data.name === name))
                                          .filter(data => data && data.type === 'DIRECTORY' && data.name)
                                          .map(data => (data && data.name) || '');
    }
    return this.CREATE_IMPORT_JOB(job).then(() => {
      //Only reset if it goes well, otherwise let the user try again.
      this.$bvModal.hide('startImportJobModal');
      this.libraryName = null;
      this.networkTargetUser = null;
      this.networkTargetPassword = null;
      this.networkTargetDomain = null;
      this.networkTargetServerAddress = null;
      this.networkTargetPort = null;
      this.networkTargetPath = null;
      this.selectedDiskOrPartition = null;
      this.usbDisks = [];
      this.importableData = [];
      this.selectedDirectories = [];
      this.$snotify.success(this.$pgettext("notification", "The import was started."));
    }).catch(error => {
      let message: string = error && error.response && error.response.data && error.response.data.message;
      let title: string = this.$pgettext("notification", "Could not start import. An error occurred.");
      if (message) {
        this.$snotify.error(message, title);
      } else {
        this.$snotify.error(title);
      }
    });
  }

  created() {
    this.GET_INSTALLED_APPS();
    this.GET_STORE_APPS();
    this.GET_SEAFILE_LIBRARIES();
  }
}
