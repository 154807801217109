






































































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import User from "../model/User";

@Component({
  components: {
    LoadingButton,
    AnimatedInput
  }
})
export default class MailDomains extends Vue {
  @Getter getStatus!: any | null;
  @Getter getMailDomains!: string[];
  @Action DELETE_MAIL_DOMAIN!: (domain: string) => Promise<any>;
  @Action ADD_MAIL_DOMAIN!: (domain: string) => Promise<any>;

  mailDomainPlaceholder: string = this.$pgettext("placeholder", "New email domain name");

  mailDomain: string = "";

  get canAdd(): boolean {
    return Boolean(this.mailDomain &&
        this.mailDomain.includes('.') &&
        !this.mailDomain.endsWith('.') &&
        !this.mailDomain.startsWith('.'));
  }

  get getDeletableMailDomains(): string[] {
    const domainArray: string[] = [];
    if (!this.getStatus) return [];
    for (let domain of this.getMailDomains) {
      domainArray.push(domain);
    }
    return domainArray;
  }

  get hasDomainsConfigured(): boolean {
    return this.getDeletableMailDomains.length > 0;
  }

  addDomain(): Promise<any> {
    return this.ADD_MAIL_DOMAIN(this.mailDomain).then(() => {
      this.$snotify.success(this.$pgettext("notification", "New domain added"));
      this.mailDomain = "";
    },
    error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
        this.$pgettext("notification", "Could not add domain. An error occurred."));
    });
  }

  deleteDomain(domain: string): Promise<any> {
    return this.DELETE_MAIL_DOMAIN(domain).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Domain deleted"));
    },
    error => {
      this.$snotify.error(this.$pgettext("server_message", error.response.data.message),
        this.$pgettext("notification", "Could not delete domain. An error occurred."));
    }).finally(() => {
      this.$bvModal.hide('deleteModal-' + domain);
    });
  }

  onDomainChanged(): void {
    this.mailDomain = this.mailDomain.replace('@', '.');
    this.mailDomain = this.mailDomain.replace('_', '-');
    this.mailDomain = this.mailDomain.replace(' ', '-');
  }
}
