














































































import { Vue, Component, Ref } from 'vue-property-decorator';
import { Action, Getter, State } from "vuex-class";
import LoadingButton from "../components/LoadingButton.vue";
import SystemInformation from "@/model/SystemInformation";
import UnikiSystem from "@/model/UnikiSystem";
import VersionComparator from "@/util/VersionComparator";
import StoreAppStore from "@/store/StoreAppStore";
import { BModal } from "bootstrap-vue";
import Product from "@/model/invoice/Product";
import SubscriptionUtils from "@/util/SubscriptionUtils";

@Component({
  components: {
    LoadingButton
  }
})
export default class SystemUpdate extends Vue {
  @Getter getSystem!: SystemInformation | null;
  @Getter getSystemVersionInStore!: string | null;
  @Getter getSystemChangeLogs!: any | null;
  @Getter getProducts!: Product[];
  @Action GET_SYSTEM_CHANGELOGS!: () => Promise<any>;
  @Action GET_SYSTEM_INFORMATION!: () => Promise<any>;
  @Action GET_SYSTEM_VERSION!: () => Promise<any>;
  @Action UPDATE_SYSTEM!: (system: UnikiSystem) => Promise<any>;
  @Action GET_PRODUCTS!: () => Promise<Product[]>;
  @Action PURCHASE_SERVICE!:({ planId, userCount }: { planId: string, userCount: number }) => Promise<any>;
  @Action GET_SUBSCRIPTIONS!: () => Promise<any[]>;
  @State("storeApp") storeAppState!: StoreAppStore;

  marked = require('marked');

  @Ref('upsellUpdatesModal') upsellUpdatesModal!: BModal;

  subsUtil: SubscriptionUtils = new SubscriptionUtils();

  monthsToPurchase: number = 12

  get getVersionInfo(): string {
    if (this.getSystem && this.getSystemVersionInStore) {
      if (this.newVersionAvailable) {
        return this.$gettext("A newer version is available. Update to:") + ' ' + this.getSystemVersionInStore;
      }
      return this.$gettext("Your device is up to date.");
    } else if (this.storeAppState.systemVersionLoading) {
      return this.$gettext("Loading...");
    } else {
      return this.$gettext("Could not get version from server.");
    }
  }

  get newVersionAvailable(): boolean {
    if (!this.getSystemVersionInStore || !this.getSystem || !this.getSystem.backendVersion) {
      return false;
    } else {
      return VersionComparator.compareVersions(this.getSystemVersionInStore as string, this.getSystem.backendVersion) > 0;
    }
  }

  get hasChangeLogForNewVersion(): boolean {
    if (this.newVersionAvailable && !!this.getSystemChangeLogs && this.getSystemVersionInStore) {
      return this.getSystemChangeLogs.hasOwnProperty(this.getSystemVersionInStore);
    } else {
      return false;
    }
  }

  get changeLogForNewVersion(): boolean {
    if (this.newVersionAvailable && !!this.getSystemChangeLogs && this.getSystemVersionInStore) {
      return this.marked(this.getSystemChangeLogs[this.getSystemVersionInStore]);
    } else {
      return false;
    }
  }

  get updateProduct(): Product | undefined {
    return this.getProducts.find((p: Product) => {
      return p.id === "fixed_updates";
    });
  }

  get updatePriceWithoutTaxes(): number {
    if (!this.updateProduct || !this.updateProduct.productPrice || !this.updateProduct.productPrice.yearly) return 0;
    return this.updateProduct.productPrice.yearly / 100;
  }

  get updatePriceOnlyTaxes(): number {
    let taxRate: number = (this.updateProduct && this.updateProduct.taxRate) ? (this.updateProduct.taxRate.taxRate || 1900) : 1900;
    return (this.updatePriceWithoutTaxes * taxRate) / 10000;
  }

  get fullAmount(): number {
    return (this.updatePriceWithoutTaxes + this.updatePriceOnlyTaxes) * this.monthsToPurchase;
  }

  updateToNewVersion(): Promise<any> | void {
    if (!this.getSystemVersionInStore) {
      this.$snotify.error(this.$pgettext("notification", "Update not possible! Could not get version from server!"));
      return;
    }
    return this.doUpdate(this.getSystemVersionInStore);
  }

  forceUpdate(): Promise<any> | void {
    if (!this.getSystem || !this.getSystem.backendVersion) {
      this.$snotify.error(this.$pgettext("notification", "Update not possible! Could not get device information!"));
      return;
    }
    return this.doUpdate(this.getSystem.backendVersion);
  }

  doUpdate(version: string): Promise<any> {
    const unikiSystem: UnikiSystem = new UnikiSystem();
    unikiSystem.version = version;

    return this.UPDATE_SYSTEM(unikiSystem).then(
      () => {
        this.$snotify.success(this.$pgettext("notification", "Starting update. Please be patient"));
      },
      error => {
        if (error.response.status === 403) {
          //Updates are not purchased, show dialog!
          this.upsellUpdatesModal.show();

        } else {
          this.$snotify.error(error.response.data.message,
            this.$pgettext("notification", "Something went wrong. Could not start update."));
        }
      }
    );
  }

  buyUpdates(): Promise<void> {
    // eslint-disable-next-line prefer-promise-reject-errors
    if (!this.updateProduct || !this.updateProduct.id) return Promise.reject();
    return this.PURCHASE_SERVICE({
      planId: this.updateProduct.id, userCount: 0
    }).then(() => {
      this.$snotify.success(this.$pgettext("notification", "Successfully ordered updates. Please try updating again."));
      this.GET_SUBSCRIPTIONS();
      this.upsellUpdatesModal.hide();
    }).catch((error: { response: { data: { message: string; }; }; }) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not order updates."));
    });
  }

  created() {
    this.GET_SYSTEM_CHANGELOGS();
    this.GET_SYSTEM_VERSION();
    this.GET_SYSTEM_INFORMATION();
    this.GET_PRODUCTS();
  }
};
