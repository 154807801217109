





































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, Action } from "vuex-class";
import BackupDevice from "@/model/backup/BackupDevice";
import BackupUtil from "@/util/BackupUtil";
import USBStorageDrive from "@/model/backup/USBStorageDrive";
import LoadingButton from "@/components/LoadingButton.vue";
import AnimatedInput from "@/components/AnimatedInput.vue";
import BackupJob from "@/model/backup/BackupJob";
import { BModal } from "bootstrap-vue";
import Subscription from "@/model/invoice/Subscription";
import Product from "@/model/invoice/Product";
import SubscriptionUtils from "@/util/SubscriptionUtils";
import InvoiceContact from "@/model/InvoiceContact";

@Component({
  components: {
    AnimatedInput, LoadingButton
  }
})
export default class BackupJobNew extends Vue {

  @Getter getInvoiceContact!: InvoiceContact | null;
  @Getter loggedInIsSigning!: boolean;
  @Getter getBackupDevices!: BackupDevice[];
  @Getter getSubscriptions!: Subscription[];
  @Getter getBackupJobs!: BackupJob[];
  @Getter getProducts!: Product[];

  @Action GET_BACKUP_DEVICES!: () => Promise< BackupDevice[]>;
  @Action GET_BACKUP_JOBS!: () => Promise< BackupJob[]>;
  @Action GET_SUBSCRIPTIONS!: () => Promise<any[]>;
  @Action CREATE_BACKUP_JOB!: (data: { device: string, format: boolean | null, cronExpression: string | null, udev: boolean | null }) => Promise<BackupJob>;
  @Action GET_PRODUCTS!: () => Promise<Product[]>;
  @Action PURCHASE_SERVICE!:({ planId, userCount }: { planId: string, userCount: number }) => Promise<any>;

  @Prop(BModal) modal!: BModal;

  subsUtil: SubscriptionUtils = new SubscriptionUtils();
  centFormat: any = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumFractionDigits: 4 })

  step: number = 0;
  setStep(current: number, next: number) {
    this.step = next;
    if (current === 0) {
      this.timetableBased = this.backupTarget === 'NETWORK';
    }
  }

  labelUsername: string = this.$gettext('Username');
  labelPassword: string = this.$gettext('Password');
  labelDomain: string = this.$gettext('Domain / Workgroup');
  labelServerAddress: string = this.$gettext('Server Address');
  labelPort: string = this.$gettext('Server Port');
  labelPath: string = this.$gettext('Share Name / Path');

  date: any;
  networkProtocol: string = "sftp";
  backupTarget: string = 'CLOUD';
  timetableBased: boolean = true;
  usbTarget: { name: string, device: USBStorageDrive } | null = null;
  networkTargetUser: string | null = null;
  networkTargetPassword: string | null = null;
  networkTargetServerAddress: string | null = null;
  networkTargetPort: string | null = null;
  networkTargetDomain: string | null = null;
  networkTargetPath: string | null = null;
  selectedDays: { name: string, number: number }[] = [];
  hour: string = "00";
  minute: string = "00";
  format: boolean = true; //TODO: Only format disk if necessary
  modalShown: boolean = false;

  availableTargets: { name: string, target: string }[] = [
    { name: this.$gettext('USB disk'), target: 'USB' },
    { name: this.$gettext('Network share'), target: 'NETWORK' },
    { name: this.$gettext('Cloud'), target: 'CLOUD' }
  ];

  availableProtocols: { name: string, mask: string, description: string }[] = [
    { name: 'ftp', mask: this.$gettext('user:password@server:port/path'), description: this.$gettext('FTP Server (ftp)') },
    { name: 'sftp', mask: this.$gettext('user:password@server:port/path'), description: this.$gettext('SSH Server (sftp)') },
    { name: 'smb', mask: this.$gettext('user:password@dpmain/server/share'), description: this.$gettext('Samba Server (smb)') }
  ];

  //Only returns USBStorageDevices
  get availableDevices(): { name: string, device: USBStorageDrive }[] {
    return this.getBackupDevices.filter((backupDevice: BackupDevice) => {
      return BackupUtil.canBeUsedForBackups(backupDevice) && (backupDevice as USBStorageDrive).model;
    }).map((device: BackupDevice) => {
      let name: string = (device as USBStorageDrive).model || '';
      if ((device as USBStorageDrive).serial) {
        name += ' (S/N: ' + (device as USBStorageDrive).serial + ')';
      }
      return { name: name, device: device as USBStorageDrive };
    });
  }

  get cloudBackupBooked (): boolean {
    return BackupUtil.cloudBackupBooked(this.getSubscriptions);
  }

  get cloudBackupPricePerGB (): string | null {
    let price: number | null = BackupUtil.cloudBackupPricePerGB(this.getProducts);
    if (price !== null) {
      return this.centFormat.format(price);
    } else {
      return null;
    }
  }

  get cloudBackupEnabled (): boolean {
    return BackupUtil.cloudBackupEnabled(this.getBackupJobs);
  }

  get getAllDays(): { name: string, number: number }[] {
    return BackupUtil.dayMapping(this);
  }

  get getHours(): string[] {
    return [...Array(24).keys()].map((val) => { return val.toString().padStart(2, '0'); });
  }

  get getMinutes(): string[] {
    return [...Array(59).keys()].filter(num => num % 5 === 0).map((val) => { return val.toString().padStart(2, '0'); });
  }

  get getDiskModel(): string | null {
    return this.usbTarget ? this.usbTarget.device.model : null;
  }

  get networkTarget(): string {
    let url = this.networkProtocol + '://' + this.esc(this.networkTargetUser) + ':' + this.esc(this.networkTargetPassword) + '@';
    if (this.networkProtocol === 'smb') {
      url += this.esc(this.networkTargetDomain) + '/' + this.esc(this.networkTargetServerAddress) + '/' + this.esc(this.networkTargetPath);
    } else if (this.networkProtocol === 'sftp' && this.networkTargetPort) {
      url += this.esc(this.networkTargetServerAddress) + ':' + this.esc(this.networkTargetPort) + ':' + this.esc(this.networkTargetPath);
    } else if (this.networkProtocol === 'sftp') {
      url += this.esc(this.networkTargetServerAddress) + ':' + this.esc(this.networkTargetPath);
    } else if (this.networkTargetPort) {
      url += this.esc(this.networkTargetServerAddress) + ':' + this.esc(this.networkTargetPort) + '/' + this.esc(this.networkTargetPath);
    } else {
      url += this.esc(this.networkTargetServerAddress) + '/' + this.esc(this.networkTargetPath);
    }
    return url;
  }

  esc(val: string | null): string {
    return (val || '').replace(/:/g, '\\:').replace(/\//g, '\\/').replace(/@/g, '\\@');
  }

  createBackupJobOrShowModal(): Promise<any> | void {
    if (this.format && this.backupTarget === 'USB' && !this.modalShown) {
      this.modalShown = true;
      return this.$bvModal.show('newBackupJobFormatDiskModal');
    } else {
      return this.createBackupJob();
    }
  }

  redirectToCustomerCenter() {
    if (this.getInvoiceContact && this.getInvoiceContact.portalURL) {
      window.open(this.getInvoiceContact.portalURL, '_blank');
    } else {
      this.$snotify.error(this.$pgettext("notification", "Could not get customer center url."));
    }
  }

  orderAndActivateCloudBackup(): Promise<any> {
    let backupProduct: Product | undefined = BackupUtil.cloudBackupProduct(this.getProducts);
    if (backupProduct && backupProduct.id) {
      return this.PURCHASE_SERVICE({
        planId: backupProduct.id, userCount: 0
      }).then(() => {
        this.$snotify.success(this.$pgettext("notification", "Successfully ordered cloud backup storage."));
        this.GET_SUBSCRIPTIONS();
        return this.createBackupJob();
      }).catch((error: { response: { data: { message: string; }; }; }) => {
        this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not order cloud backup storage."));
      });
    } else {
      this.$snotify.error(this.$pgettext("notification", "Cloud backup storage product not found."));
      return Promise.resolve();
    }
  }

  closeFormatDiskModal(): void {
    this.modalShown = false;
    return this.$bvModal.hide('newBackupJobFormatDiskModal');
  }

  createBackupJob(): Promise<any> | void {

    let targetDevice: string;
    if (this.backupTarget === 'NETWORK') {
      targetDevice = this.networkTarget;
    } else if (this.backupTarget === 'USB' && this.usbTarget) {
      //usbTarget can not be null because of validation above.
      targetDevice = this.usbTarget.device.deviceName || '';
    } else {
      targetDevice = "cloud://";
      this.timetableBased = false;
    }

    let cron: string = '';
    if (this.timetableBased) {
      cron = BackupUtil.createCronExpression(this.selectedDays, this.minute, this.hour) || '';
    }

    return this.CREATE_BACKUP_JOB({
      device: targetDevice, format: this.format, cronExpression: cron, udev: !this.timetableBased
    }).then(() => {
      this.$snotify.success(this.$pgettext('notification', 'New backup job was created.'));
      this.closeModals();
    }).catch((error) => {
      this.$snotify.error(error.response.data.message,
        this.$pgettext("notification", "Could not create backup job."));
    });
  }

  closeModals() {
    this.$bvModal.hide('newBackupJobFormatDiskModal');
    if (this.modal) this.modal.hide();
  }

  beforeMount (): void {
    this.GET_BACKUP_DEVICES().catch((error) => {
      this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not get backup devices"));
    });
    this.GET_BACKUP_JOBS().catch((error) => {
      this.$snotify.error(error.response.data.message,
          this.$pgettext("notification", "Could not get backup jobs"));
    });
    this.GET_SUBSCRIPTIONS();
    this.GET_PRODUCTS();
  }
};
