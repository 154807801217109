

































































































































import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AnimatedInput from "../components/AnimatedInput.vue";
import LoadingButton from "../components/LoadingButton.vue";
import ConfigValue from "@/model/ConfigValue";
import LDAPConfig from "@/model/LDAPConfig";
import AttributeMapping from "@/model/AttributeMapping";
import Group from "@/model/Group";
import App from "@/model/App";
import UserUtils from "@/util/UserUtils";

@Component({
  components: {
    LoadingButton,
    AnimatedInput
  }
})
export default class AuthenticationSettings extends Vue {
  @Getter getApps!: App[];
  @Getter getGroups!: Group[];
  @Getter getSystemConfigValues!: ConfigValue[];
  @Action SET_SYSTEM_CONFIG_VALUES!: ({ context, values }: { context: string, values: ConfigValue[] }) => Promise<ConfigValue[]>;
  @Action GET_SYSTEM_CONFIG_VALUES!: (context: string) => Promise<ConfigValue[]>;
  @Action TEST_LDAP_CONFIG_VALUE!: (config: LDAPConfig) => Promise<any>;
  @Action GET_GROUPS!: () => Promise<Group[]>;

  hostPlaceHolder: string = this.$pgettext("placeholder", "LDAP/ActiveDirectory Host/IP");
  portPlaceHolder: string = this.$pgettext("placeholder", "LDAP/ActiveDirectory Port");
  searchDnPlaceHolder: string = this.$pgettext("placeholder", "Bind-DN");
  searchPasswordPlaceHolder: string = this.$pgettext("placeholder", "Bind-Password");
  userFilterPlaceHolder: string = this.$pgettext("placeholder", "Additional User Filter");
  userBaseDnPlaceHolder: string = this.$pgettext("placeholder", "User Base-DN");
  groupFilterPlaceHolder: string = this.$pgettext("placeholder", "Group Filter");
  groupBaseDnPlaceHolder: string = this.$pgettext("placeholder", "Group Base-DN");
  userIdAttributePlaceHolder: string = this.$pgettext("placeholder", "User ID Attribute");
  groupMappingExternalDn: string = this.$pgettext("placeholder", "Group DN");

  systemGroupCNs = [ 'admin', 'signing' ];
  systemGroupDescriptions = [
    {
      dn: 'cn=admin,ou=Groups,dc=uniki,dc=de',
      cn: "admin",
      name: this.$gettext("Administrator"),
      description: this.$gettext("May configure the system, install and uninstall apps.")
    },
    {
      dn: 'cn=signing,ou=Groups,dc=uniki,dc=de',
      cn: "signing",
      name: this.$gettext("Manager"),
      description: this.$gettext("May obtain subscriptions for services, install apps and edit app groups.")
    }
  ];

  loading: boolean = true;
  active: boolean = false;
  host: string | null = null;
  port: number | null = 389;
  searchDn: string | null = null;
  searchPassword: string | null = null;
  userIdAttribute: string | null = null;
  userFilter: string | null = null;
  userBaseDn: string | null = null;
  groupFilter: string | null = null;
  groupBaseDn: string | null = null;
  disallowInternalAuthentication: boolean = false;
  onlySyncUsersWithGroups: boolean = false;
  syncExternalMailToExternalMail: boolean = true;
  groupMapping: { external: string, internal: { name: string, dn: string } | null }[] = [];

  get getGroupNamesToDn(): { name: string, dn: string }[] {
    return this.getGroups.map(group => { return { name: this.getGroupName(group) || '', dn: group.dn || '' }; }).filter(entry => !!entry.name);
  }

  get adminGroupMapped(): boolean {
    return Boolean(this.groupMapping.find(mapping => Boolean(mapping.internal && mapping.internal.dn === 'cn=admin,ou=Groups,dc=uniki,dc=de')));
  }

  getGroupName(group: Group): string | null {
    if (this.isSystemGroup(group)) {
      let desc = this.systemGroupDescriptions.find(description => group.cn === description.cn);
      return desc ? desc.name : group.cn;
    } else {
      const app: App | undefined = this.correspondingApp(group);
      return app ? app.title : group.description;
    }
  }

  correspondingApp(group: Group): App | undefined {
    return this.getApps.find(app => app.userGroup === group.dn);
  }

  isSystemGroup(group: Group): boolean {
    return Boolean(this.systemGroupCNs.indexOf(group.cn || '') >= 0);
  }

  addGroupMapping(): void {
    this.groupMapping.push({ external: '', internal: null });
  }

  removeGroupMapping(mapping: { external: string, internal: { name: string, dn: string } | null }): void {
    let index = this.groupMapping.indexOf(mapping);
    if (index > -1) {
      this.groupMapping.splice(index, 1);
    }
    if (this.disallowInternalAuthentication) this.disallowInternalAuthentication = this.adminGroupMapped;
  }

  get ldapConfig(): LDAPConfig {
    let ldapConfig: LDAPConfig = new LDAPConfig();
    ldapConfig.enabled = true;
    ldapConfig.host = this.host;
    ldapConfig.port = this.port;
    ldapConfig.searchDn = this.searchDn;
    ldapConfig.searchPassword = this.searchPassword;
    ldapConfig.userFilter = this.userFilter;
    ldapConfig.userBaseDn = this.userBaseDn;
    ldapConfig.groupFilter = this.groupFilter;
    ldapConfig.groupBaseDn = this.groupBaseDn;
    ldapConfig.userAttributeMapping = [];
    ldapConfig.userAttributeMapping.push({ internal: 'cn', external: this.userIdAttribute });
    ldapConfig.syncExternalMailToInternalMail = !this.syncExternalMailToExternalMail;
    if (this.groupMapping && this.groupMapping.length > 0) {
      ldapConfig.onlySyncUsersWithGroups = this.onlySyncUsersWithGroups;
      ldapConfig.groupMapping = [];
      for (let mapping of this.groupMapping) {
        if (mapping.internal) {
          ldapConfig.groupMapping.push({ external: mapping.external, internal: mapping.internal.dn });
        }
      }
    }
    return ldapConfig;
  }

  testConfig(): void {
    this.TEST_LDAP_CONFIG_VALUE(this.ldapConfig).then(response => {
      if (response.success) {
        this.$snotify.success(this.$pgettext('notification', 'Success. Users found: ') + response.entries);
      } else {
        this.$snotify.error(this.$pgettext('notification', 'Failed to test config: ') + response.error);
      }
    }).catch(error => {
      this.$snotify.error(this.$pgettext('notification', 'Failed to test config: ') + error.response.data.message);
    });
  }

  sendConfig(): Promise<ConfigValue[]> {
    let values: ConfigValue[] = [];

    let enabled: ConfigValue = new ConfigValue();
    enabled.id = 'enabled';
    enabled.value = this.active;

    values.push(enabled);

    let disallowInternalAuthentication: ConfigValue = new ConfigValue();
    disallowInternalAuthentication.id = 'disallow.internal.authentication';

    if (this.active) {
      disallowInternalAuthentication.value = this.disallowInternalAuthentication;
      values.push(disallowInternalAuthentication);

      let directories: LDAPConfig[] = [];
      directories.push(this.ldapConfig);

      let directoryConfig: ConfigValue = new ConfigValue();
      directoryConfig.id = 'directories';
      directoryConfig.value = directories;

      values.push(directoryConfig);
    } else {
      disallowInternalAuthentication.value = false;
      values.push(disallowInternalAuthentication);
    }

    return this.SET_SYSTEM_CONFIG_VALUES({ context: 'external.authentication', values: values });
  }

  created() {
    this.GET_GROUPS().finally(() => {
      this.GET_SYSTEM_CONFIG_VALUES('external.authentication').finally(() => {

        this.active = UserUtils.externalAuthenticationEnabled(this.getSystemConfigValues);

        let directoryConfig: ConfigValue | undefined = this.getSystemConfigValues.find((value: ConfigValue) => {
          return value.id === 'directories' && value.context === 'external.authentication';
        });
        if (directoryConfig && Array.isArray(directoryConfig.value) && Array(directoryConfig.value).length > 0) {
          let directories: LDAPConfig[] = directoryConfig.value.map((value: LDAPConfig) => Object.assign(new LDAPConfig(), value));
          let ldapConfig: LDAPConfig = directories[0];
          this.host = ldapConfig.host;
          this.port = ldapConfig.port;
          this.searchDn = ldapConfig.searchDn;
          this.searchPassword = ldapConfig.searchPassword;
          this.userFilter = ldapConfig.userFilter;
          this.userBaseDn = ldapConfig.userBaseDn;
          this.groupFilter = ldapConfig.groupFilter;
          this.groupBaseDn = ldapConfig.groupBaseDn;
          this.syncExternalMailToExternalMail = !ldapConfig.syncExternalMailToInternalMail;
          if (ldapConfig.userAttributeMapping) {
            let cnMapping: AttributeMapping | undefined = ldapConfig.userAttributeMapping.find(mapping => mapping.internal === 'cn');
            if (cnMapping) this.userIdAttribute = cnMapping.external;
          }
          if (ldapConfig.groupMapping) {
            this.onlySyncUsersWithGroups = Boolean(ldapConfig.onlySyncUsersWithGroups);
            for (let mapping of ldapConfig.groupMapping) {
              if (mapping.external && mapping.internal) {
                let group: Group | undefined = this.getGroups.find(group => group.dn === mapping.internal);
                if (group && group.dn) {
                  let name: string | null = this.getGroupName(group);
                  if (name) {
                    this.groupMapping.push({ external: mapping.external, internal: { name: name, dn: group.dn } });
                  }
                }
              }
            }
          }
        }

        let disallowInternalAuthentication: ConfigValue | undefined = this.getSystemConfigValues.find((value: ConfigValue) => {
          return value.id === 'disallow.internal.authentication' && value.context === 'external.authentication';
        });
        this.disallowInternalAuthentication = Boolean(disallowInternalAuthentication && disallowInternalAuthentication.value === true);
      }).finally(() => {
        this.loading = false;
      });
    });
  }
}
